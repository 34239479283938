<template>
	<div class="app-container" style="background-color: #fff;padding-bottom: 250px;min-height: 700px;">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" >
			<el-form-item label="活动名称" label-width="100px" prop="activeName" style="margin-bottom: 30px;">
				<el-input :disabled="type==2" v-model="ruleForm.activeName" placeholder="最多输入50个字" maxlength="50" style="width:400px"></el-input>
			</el-form-item>
			<el-form-item label="活动时间" label-width="100px" prop="activityTime" style="margin-bottom: 30px;">
				<el-date-picker :disabled="type==2" v-model="ruleForm.activityTime" type="datetimerange" :picker-options="pickerOptions"
				range-separator="～" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				<div style="font-size: 14px;color: #999;">用户需要在活动时间内成团，超时则拼团失败</div>
			</el-form-item>
			<el-form-item label="拼团商品" label-width="100px" prop="activeGoodsList">
				<el-button :disabled="type==2" type="primary" @click="choseActive" style="margin-bottom: 20px;">选择商品</el-button>
				<el-table :data="ruleForm.activeGoodsList" style="width: 100%;margin-bottom: 10px;">
					<el-table-column label="商品名称" width="300">
						<template slot-scope="scope">
							<div class="product-info">
								<!-- <img v-if="scope.row.skuImgUrl" :src="scope.row.skuImgUrl" /> -->
								<!-- <svg-icon v-else icon-class="noImgIcon" /> -->
								<div>
									<div>
										<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.goodsName}}</pre>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="salePrice" label="商品图片">
						<template slot-scope="scope">
							<div class="product-info">
								<img v-if="scope.row.thumbImgUrl" :src="scope.row.thumbImgUrl" />
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="salePrice" label="销售价格"></el-table-column>
					<el-table-column label="拼团价">
						<template scope="scope">
							<div style="display:flex;justify-content:flex-start;align-items:center;">
								<div style="white-space: nowrap;margin-right: 5px;color:#F56C6C;">￥{{scope.row.secKillPrice}}</div>
								<buttonPermissions :datas="'updateGoodsPrice'">
									<i class="el-icon-edit-outline" @click="goodsEdit(scope.row,1)"></i>
								</buttonPermissions>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="每单限购">
						<template scope="scope">
							<el-input onkeyup="this.value=this.value.replace(/\D/g,'')" :disabled="type==2" v-model="scope.row.quotaNum"
							size="mini" class="input-with-select" style="width: 120px;margin: 0 5px;"></el-input>
						</template>
					</el-table-column>
					<!-- <el-table-column label="成团奖励">
						<template scope="scope">
							<el-input onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);" :disabled="type==2" v-model="scope.row.groupWard"
							size="mini" class="input-with-select" style="width: 120px;margin: 0 5px;"></el-input>
						</template>
					</el-table-column> -->
					<el-table-column label="操作" width="120">
						<template slot-scope="scope">
							<span v-if="type!=2" style="color:#F56C6C;cursor:pointer;" @click="deleteChose(scope.row)">删除</span>
						</template>
					</el-table-column>
				</el-table>
			</el-form-item>
			<div style="height: 20px;"></div>
			<el-form-item label="拼团有效期" label-width="100px" required prop="">
				<div class="flex center">
					<el-select :disabled="type==2" v-model="ruleForm.dayTime" placeholder="请选择" filterable clearable style="width: 100px;margin-right: 10px;">
						<el-option v-for="(item,index) in dayList" :key="index" :label="item" :value="item"></el-option>
					</el-select>
					<span style="margin-right: 10px;">日</span>
					<el-select :disabled="type==2" v-model="ruleForm.hourTime" placeholder="请选择" filterable clearable style="width: 100px;margin-right: 10px;">
						<el-option v-for="(item,index) in hourList" :key="index" :label="item" :value="item"></el-option>
					</el-select>
					<span style="margin-right: 10px;">时</span>
					<el-select :disabled="type==2" v-model="ruleForm.minuteTime" placeholder="请选择" filterable clearable style="width: 100px;margin-right: 10px;">
						<el-option v-for="(item,index) in minuteList" :key="index" :label="item" :value="item"></el-option>
					</el-select>
					<span>分</span>
				</div>
				<div style="font-size: 14px;color: #999;">活动正在进行中，用户下单之后在设置的有效期内不能退款</div>
			</el-form-item>
			<el-form-item label="额外成团奖" label-width="100px" prop="extraReward" style="margin-bottom: 40px;">
				<el-input :disabled="type==2" v-model="ruleForm.extraReward" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);" style="width:100px;"></el-input>
				元<span style="font-size: 14px;color: #999;">（拼团成功后，给团长的额外奖励，若填0或不填，则额外成团奖励不发）</span>
			</el-form-item>
			<el-form-item label="成团人数" label-width="100px" prop="groupNumber" style="margin-bottom: 40px;">
				<el-input :disabled="type==2" v-model.number="ruleForm.groupNumber" maxlength="1" onkeyup="this.value=this.value.replace(/\D/g,'')" style="width:100px;"></el-input>
				人<span style="font-size: 14px;color: #999;">（成团人数需设置在2-5数值范围内）</span>
			</el-form-item>
			<el-form-item label="最多成团数" label-width="100px" prop="maxGroupNumber" style="margin-bottom: 40px;">
				<el-input :disabled="type==2" v-model.number="ruleForm.maxGroupNumber" maxlength="2" onkeyup="this.value=this.value.replace(/\D/g,'')" style="width:100px;"></el-input>
				个<span style="font-size: 14px;color: #999;">（进行当中的拼团，团长最多能够开团多少个,需设置0-10数值范围内，填0或不填，则不限制开团数）</span>
			</el-form-item>
			<!-- <el-form-item label="模拟成团" required prop="activityTime">
				<div class="flex center" style="margin-top: 10px;">
					<el-radio v-model="ruleForm.groupRadio" label="1">开启</el-radio>
					<el-radio v-model="ruleForm.groupRadio" label="2">关闭</el-radio>
				</div>
				<div style="color: #999;font-size: 12px;margin-top: 10px;width: 500px;line-height: 20px;">开启模拟成团后，满足条件的团，系统将会模拟“匿名买家”凑满该团，仅需对真实拼团买家发货。建议合理开启，以提高成团率。</div>
				<div style="margin-top: 10px;">参团人数≥
					<el-input :disabled="type==2" v-model="ruleForm.activeName" maxlength="1" style="width:100px"></el-input>
				的团</div>
			</el-form-item> -->
			<!-- <el-form-item label="主动参团" prop="joinGroup" style="margin-bottom: 40px;" required>
				<div class="flex center" style="margin-top: 10px;">
					<el-radio :disabled="type==2" v-model="ruleForm.joinGroup" label="1">开启</el-radio>
					<el-radio :disabled="type==2" v-model="ruleForm.joinGroup" label="2">关闭</el-radio>
				</div>
			</el-form-item> -->
			<el-form-item label="免支付开团资格" label-width="120px" prop="noPayType" style="margin-bottom: 40px;" required>
				<div class="flex center" style="margin-top: 10px;">
					<el-radio :disabled="type==2" v-model="ruleForm.noPayType" label="0">针对这一期</el-radio>
					<el-radio :disabled="type==2" v-model="ruleForm.noPayType" label="1">针对全部</el-radio>
				</div>
				<div style="font-size: 14px;color: #999;">下单支付拼团商品后，免支付的开团资格，是针对这一期活动，还是针对全部的活动</div>
			</el-form-item>
			<div style="margin-top: 30px;">
				<el-form-item label="拼团海报：" label-width="100px" :inline-message="true" prop="publicityImg" :rules="[{required:true,message:'请上传拼团海报',trigger: 'blur'}]">
					<el-upload :disabled="type==2" class="avatar-uploader" :action="imgApi" :show-file-list="false"
						:on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload">
						<img v-if="ruleForm.publicityImg"
							:src="ruleForm.publicityImg+'?x-oss-process=image/resize,m_fixed,h_146,w_146'"
							style="width:146px;height:146px;" class="avatar" />
						<i v-else class="el-icon-plus"
							style="width:144px;height:84px;font-size: 24px;color:#999999;margin-top:60px;"></i>
					</el-upload>
					<div style="color: #666;">建议尺寸375px * 375px，支持JPG、PNG、JPEG格式</div>
				</el-form-item>
			</div>
			<el-form-item label="分享标题：" label-width="100px" prop="shareTitle">
				<el-input :disabled="type==2" v-model="ruleForm.shareTitle" placeholder="请输入活动分享标题" maxlength="30" style="width:200px">
				</el-input>
			</el-form-item>
			<div style="margin-top: 30px;">
				<el-form-item label="分享图片：" label-width="100px" :inline-message="true" prop="shareImgUrl"
					:rules="[{required:true,message:'请上传分享图片',trigger: 'blur'}]">
					<el-upload :disabled="type==2" class="avatar-uploader" :action="imgApi" :show-file-list="false"
						:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<img v-if="ruleForm.shareImgUrl"
							:src="ruleForm.shareImgUrl+'?x-oss-process=image/resize,m_fixed,h_146,w_146'"
							style="width:146px;height:146px;" class="avatar" />
						<i v-else class="el-icon-plus"
							style="width:144px;height:84px;font-size: 24px;color:#999999;margin-top:60px;"></i>
					</el-upload>
					<div style="color: #666;">建议5：4尺寸，支持JPG、PNG、JPEG格式</div>
				</el-form-item>
			</div>
			<el-form-item label="活动规则说明：" label-width="120px" prop="rules">
				<div class="baseInformtion">
					<div class="baseBottom-box" style="margin-bottom: 100px;">
						<quill-editor :disabled="type==2" ref="myTextEditor" v-model="ruleForm.rules" :options="quillOption"
							style="width:80%;min-height: 300px;height:650px;"></quill-editor>
					</div>
				</div>
			</el-form-item>
		</el-form>
		<div class="bottom-save-btn">
			<el-button v-if="type==2" style="width: 150px;" @click="handleCancel">返回</el-button>
			<el-button v-else style="width: 150px;" @click="handleCancel">取消</el-button>
			<el-button v-if="type!=2" style="width: 150px;margin-left: 40px;" type="primary" @click="saveBtn" :disabled='loading'
			:loading="loading">保存</el-button>
		</div>
		<el-dialog title="选择商品" :visible.sync="addProductDialogShow" width="1500px" class="dialog">
			<select-produce api="activitycouponChoseproductlist" :params="['goodsName', 'barCode']" :selectedData="ruleForm.activeGoodsList"
			@getSelectList="getSelectList" :visible.sync="addProductDialogShow" v-if="addProductDialogShow"></select-produce>
		</el-dialog>
		<el-dialog title="选择规格" :visible.sync="editProductDialogShow" width="1400px" class="dialog">
			<el-tabs :disabled="type==2" type="border-card" v-model="editableTabsValue" @tab-click="handleClick">
				<el-tab-pane label="选择商品规格" name="1">
					<el-table height="350" v-if="editableTabsValue==1" :data="skuList" style="width: 100%;" :row-key="getRowKeys" @selection-change="handleSelectionChange"
					ref="tab">
						<el-table-column type="selection" width="55"></el-table-column>
						<el-table-column prop="specValues" label="规格名称"></el-table-column>
						<el-table-column prop="salePrice" label="销售价格"></el-table-column>
						<el-table-column prop="stock" label="现有库存"></el-table-column>
						<el-table-column label="状态" width="120">
							<template slot-scope="scope">
								<div v-if="scope.row.stock==0" class="table-button" style="color: #f00;">库存不足</div>
								<div v-else-if="scope.row.hasSecKill" class="table-button" style="color: #f00;">已参加</div>
							</template>
						</el-table-column>
					</el-table>
					<div style="display: flex;justify-content: space-between;margin-right: 20px;margin-left: 13px;margin-top: 20px;">
						<el-checkbox :disabled="type==2" @change="tabCheckedChange" v-model="checked">全选</el-checkbox>
						<div>
							<el-button style="margin-right: 20px;width: 80px;" type="infor" @click="cancelEdit">取消</el-button>
							<el-button :disabled="type==2" style="width: 80px;" type="primary" @click="joinSkill()">下一步</el-button>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="设置价格和库存" name="2">
					<el-table height="350" v-if="editableTabsValue==2" :data="checkSkuList" style="width: 100%;" :row-key="getRowKeys2" @selection-change="handleSelectionChange2"
					ref="tabSet">
						<el-table-column type="selection" width="55"></el-table-column>
						<el-table-column prop="specValues" label="规格名称"></el-table-column>
						<el-table-column label="拼团价">
							<template scope="scope">
								<div>
									<el-input :disabled="type==2" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
									v-model="scope.row.secKillPrice" size="mini" class="input-with-select" style="width: 120px;margin: 0 5px;"></el-input>
									<div style="margin-top: 10px;margin-left: 10px;">价格(元)：{{scope.row.salePrice}}</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="拼团库存">
							<template scope="scope">
								<div>
									<el-input onkeyup="this.value=this.value.replace(/\D/g,'')" :disabled="type==2" v-model="scope.row.secKillStock"
									size="mini" class="input-with-select" style="width: 120px;margin: 0 5px;"></el-input>
									<div style="margin-top: 10px;margin-left: 10px;">现有库存：{{scope.row.stock}}</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="直接奖励">
							<template scope="scope">
								<div>
									<el-input onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);" :disabled="type==2" v-model="scope.row.directReward"
									size="mini" class="input-with-select" style="width: 120px;margin: 0 5px;"></el-input>
									<div style="margin-top: 10px;margin-left: 10px;color: #fff;">.</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="间接奖励">
							<template scope="scope">
								<div>
									<el-input onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);" :disabled="type==2" v-model="scope.row.indirectReward"
									size="mini" class="input-with-select" style="width: 120px;margin: 0 5px;"></el-input>
									<div style="margin-top: 10px;margin-left: 10px;color: #fff;">.</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="120">
							<template slot-scope="scope">
								<div v-if="type!=2" class="table-button" style="background-color: #409EFF;border-radius: 5px;width: 90px;color: #fff;text-align: center;height: 35px;line-height: 35px;"
								@click="cancelKillBefore(scope.row,1)">取消参加</div>
							</template>
						</el-table-column>
					</el-table>
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
						<el-checkbox @change="tabCheckedChange2" :disabled="type==2" v-model="checked2">全选</el-checkbox>
						<el-button style="margin-left: 20px;" type="text" :disabled="type==2" @click="cancelKillBefore({},2)">批量删除</el-button>
					</div>
					<div style="display: flex;align-items: center;justify-content: space-between;">
						<div style="display: flex;align-items: center;">
							<div style="margin-left: 10px;">批量设置</div>
							<el-button style="margin-left: 20px;" type="text" :disabled="type==2" @click="setPrice">拼团价</el-button>
							<el-button style="margin-left: 20px;" type="text" :disabled="type==2" @click="setStock">拼团库存</el-button>
							<el-button style="margin-left: 20px;" type="text" :disabled="type==2" @click="setPoint(1)">直接奖励</el-button>
							<el-button style="margin-left: 20px;" type="text" :disabled="type==2" @click="setPoint(2)">间接奖励</el-button>
						</div>
						<div>
							<el-button style="margin-right: 20px;width: 80px;" type="infor" @click="cancelEdit">取消</el-button>
							<el-button style="margin-right: 20px;width: 80px;" type="primary" :disabled="type==2" @click="commitEdit">确定</el-button>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>
		<el-dialog :title="batchTitle" :visible.sync="batchEditDialog" width="450px" class="dialog">
			<div>
				<el-input @input="inputChange" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
				v-model="batchKillPrice" class="input-with-select" style="width: 400px;margin: 0 5px;" placeholder="请输入数值"></el-input>
				<div style="display: flex;justify-content: flex-end;margin-top: 20px;">
					<el-button style="margin-left: 20px;" type="warning" @click="batchEditDialog=false">取消</el-button>
					<el-button style="margin-left: 20px;" type="primary" @click="batchEditCommit()">确定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import buttonPermissions from '@/components/buttonPermissions';
	import selectProduce from './selectMultiPro.vue';
	import {
		addGroupActivity,
		editGroupActivity,
		groupActivityView,
		getGoodsSampleDetail,
	} from "@/api/goods.js";
	import {
		quillEditor
	} from 'vue-quill-editor'
	import config from '../../../config/index.js';
	import quillConfig from '@/components/quill-config.js'
	export default {
		components: {
			buttonPermissions,
			selectProduce,
			quillEditor,
		},
		data() {
			return {
				ruleForm: {
					activeName: '',
					activityTime: '',
					groupTime: '',
					groupNumber: '', //成团人数
					maxGroupNumber:'',//最多成团数
					groupRadio: '2',
					radioNumber: '', //参团人数大于多少
					dayTime: '',
					hourTime: '',
					minuteTime: '',
					joinGroup:'1',//主动参团
					shareTitle: '', //分享标题
					shareImgUrl: '', //分享图片
					rules: '', //活动规则说明
					activeGoodsList:[],
					noPayType:'0',//免支付开团资格时间范围 免支付开团资格 0针对这一期 1针对全部
					publicityImg:'',
					extraReward:'',//额外成团奖励
				},
				// activeGoodsList: [],
				rules: {
					activeName: [{
						required: true,
						message: '请填写活动名称',
						trigger: 'blur'
					}],
					activityTime: [{
						required: true,
						message: '请选择活动时间',
						trigger: 'change'
					}],
					groupNumber: [{
						required: true,
						message: '请填写成团人数',
						trigger: 'blur',
					},
					{ type:"number" ,min: 2, max: 5, message: '大小要在2到5', trigger: 'blur' }
					],
					// maxGroupNumber: [{
					// 	required: true,
					// 	message: '请填写最多成团数',
					// 	trigger: 'blur',
					// }],
					shareTitle: [{
						required: true,
						trigger: 'blur',
						message: '请输入活动分享标题',
					}],
					rules: [{
						required: true,
						trigger: 'blur',
						message: '请填写活动规则',
					}],
					activeGoodsList:[{
						type: 'array',
						required: true,
						message: '请选择拼团商品',
						trigger: 'change'
					}]
				},
				pickerOptions: { //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				timeList: [{
					timeId: 1,
					timeValue: '1点'
				}],
				addProductDialogShow: false,
				loading: false,
				editProductDialogShow: false,
				editableTabsValue: '1',
				currentEditGoods: null,
				atyId: 0, //活动id
				skuList: [], //全部规格列表
				checkSkuList: [], //选择的规格列表
				checked: false, //规格弹窗添加 全选
				checked2: false, //规格弹窗删除 全选
				multipleSelection: [],
				multipleSelection2: [],
				batchEditDialog: false,
				batchEditType: 1, //1 批量设置价格 2批量设置库存,3批量设置金豆
				batchKillPrice: '',
				batchTitle: '批量设置',
				baseData: [],
				type: 0, //0是创建，1是编辑 2是查看
				dayList: [],
				hourList: [],
				minuteList: [],
				imgApi: config.UPLOAD_IMG,
				imgUrl: config.IMG_BASE,
				quillOption: quillConfig, //富文本
			}
		},
		beforeMount() {
			this.atyId = this.$route.query.id || 0;
			this.type = this.$route.query.type || 0;
			if (this.atyId != 0) {
				this.initData();
			}
			this.dayList = [];
			for (var i = 0; i < 6; i++) {
				this.dayList.push(i);
			}
			for (var j = 0; j < 25; j++) {
				this.hourList.push(j);
			}
			for (var k = 0; k < 61; k++) {
				this.minuteList.push(k);
			}

		},
		methods: {
			async initData() {
				let data = {
					id: +this.atyId
				};
				let res = await groupActivityView(data);
				if (res.success) {
					this.ruleForm.activeName = res.data.activityName;
					this.ruleForm.groupNumber = res.data.groupPeopleCount;
					this.ruleForm.activityTime = [res.data.beginTime, res.data.endTime];
					this.ruleForm.maxGroupNumber = res.data.groupCount;
					this.ruleForm.dayTime = parseInt(res.data.validityTime / (60 * 24));
					this.ruleForm.hourTime = parseInt(res.data.validityTime / 60) % 24;
					this.ruleForm.minuteTime = res.data.validityTime % (60 * 24) % 60;
					this.ruleForm.joinGroup = res.data.isShowActivelyGroup?'1':'2';
					this.ruleForm.publicityImg = res.data.publicityImg;
					this.ruleForm.shareImgUrl = res.data.shareImg;
					this.ruleForm.shareTitle = res.data.shareTitle;
					this.ruleForm.extraReward = res.data.extraReward;
					this.ruleForm.rules = res.data.rules;
					this.ruleForm.noPayType = res.data.noPayType+'';
					var list = [];
					res.data.goodsList.map(item => {
						item.quotaNum = parseInt(item.quotaNum);
						var obj = {};
						obj.id = item.goodsId;
						obj.quotaNum = item.limitNum||0;
						obj.goodsName = item.goodsName;
						obj.salePrice = item.salePrice;
						obj.thumbImgUrl = item.thumbImgUrl;
						var skuList = [];
						var minPrice = 0;
						item.skuList.map((skuItem, index) => {
							skuItem.groupPrice = (+skuItem.groupPrice).toFixed(2);
							var price = +skuItem.groupPrice;
							if (index == 0) {
								minPrice = price;
							} else {
								if (price < minPrice) {
									minPrice = price;
								}
							}
							var skuObj = {};
							skuObj.directReward = skuItem.directReward;
							skuObj.indirectReward = skuItem.indirectReward;
							skuObj.secKillPrice = skuItem.groupPrice;
							skuObj.skuId = skuItem.skuId;
							skuObj.secKillStock = skuItem.stock;
							skuObj.salePrice = item.salePrice;
							skuObj.stock = skuItem.originalStock;
							skuList.push(skuObj);
							return skuItem;
						})
						obj.secKillPrice = minPrice;
						obj.skuList = skuList;
						list.push(obj);
						return item;
					})
					this.ruleForm.activeGoodsList = list;
					this.baseData = list;
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
			},
			getSelectList(data) {
				this.currentEditGoods = data[0];
				this.seckillSkuList();
			},
			choseActive() {
				this.editableTabsValue = "1";
				this.addProductDialogShow = true;
			},
			handleCancel() {
				this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
					(item) => {
						return item.path != this.$route.path;
					}
				);
				this.$router.push({
					path: '/market/spellGroup/index'
				});
			},
			saveBtn() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						console.log("-----", this.ruleForm)
						if (!this.ruleForm.activeGoodsList.length) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '请选择活动商品'
							});
							return;
						}
						// var isNoLimitNum = false;
						// this.ruleForm.activeGoodsList.map(item => {
						// 	if (typeof(item.quotaNum) == 'undefined' || item.quotaNum.length == 0) {
						// 		isNoLimitNum = true;
						// 	}
						// 	return item;
						// })
						// if (isNoLimitNum) {
						// 	this.$message({
						// 		showClose: true,
						// 		type: 'error',
						// 		message: '有商品限购数量设置错误'
						// 	});
						// 	return;
						// }
						if (!this.ruleForm.dayTime && !this.ruleForm.hourTime && !this.ruleForm.minuteTime) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '请选择拼团有效期'
							});
							return;
						}
						if(this.ruleForm.dayTime*24*60+this.ruleForm.hourTime*60+this.ruleForm.minuteTime<15){
							this.$message({
								showClose: true,
								type: 'error',
								message: '拼团有效期需不能低于15分钟'
							});
							return;
						}
						if(this.ruleForm.maxGroupNumber.length>0 && this.ruleForm.maxGroupNumber>10){
							this.$message({
								showClose: true,
								type: 'error',
								message: '最多成团数需要设置在0-10数值范围内'
							});
							return;
						}
						this.loading = true;
						this.seckillGoodsUpdate();
					} else {
						return false;
					}
				});
			},
			async seckillSkuList() {
				let data = {
					id: this.currentEditGoods.id,
				};
				let res = await getGoodsSampleDetail(data);
				if (res.success) {
					this.skuList = res.data.skuSampleVOList;
					this.checkSkuList = [];
					this.skuList.map(item => {
						if (item.hasSecKill) {
							this.checkSkuList.push(item);
						}
						return item;
					})
					this.addProductDialogShow = false;
					let that = this;
					setTimeout(function() {
						that.editProductDialogShow = true;
					}, 500)
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
			},

			async seckillSkuListNew() {
				let data = {
					id: this.currentEditGoods.id,
				};
				let res = await getGoodsSampleDetail(data);
				if (res.success) {
					this.editableTabsValue = "2";
					this.addProductDialogShow = false;
					let that = this;
					res.data.skuSampleVOList.map(item => {
						item.hasSecKill = false;
						return item;
					})
					this.checkSkuList.map(cItem => {
						res.data.skuSampleVOList.map(item => {
							if (cItem.skuId == item.skuId) {
								cItem.specValues = item.specValues;
								item.hasSecKill = true;
							}
							return item;
						})
						return cItem;
					})

					this.skuList = res.data.skuSampleVOList;
					setTimeout(function() {
						that.editProductDialogShow = true;
					}, 500)
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
			},

			//创建活动
			async seckillGoodsUpdate() {
				var list = [];
				this.ruleForm.activeGoodsList.map(item => {
					item.quotaNum = parseInt(item.quotaNum);
					var obj = {};
					obj.goodsId = item.id;
					obj.limitNum = parseInt(item.quotaNum);
					var skuList = [];
					item.skuList.map(skuItem => {
						var skuObj = {};
						skuObj.directReward = skuItem.directReward;
						skuObj.indirectReward = skuItem.indirectReward;
						skuObj.groupPrice = skuItem.secKillPrice;
						skuObj.skuId = skuItem.skuId;
						skuObj.stock = skuItem.secKillStock;
						skuList.push(skuObj);
						return skuItem;
					})
					obj.skuList = skuList;
					list.push(obj);
					return item;
				})
				var time = this.ruleForm.dayTime * 24 * 60 + this.ruleForm.hourTime * 60 + this.ruleForm.minuteTime;
				let data = {
					activityName: this.ruleForm.activeName,
					beginTime: this.ruleForm.activityTime[0],
					endTime: this.ruleForm.activityTime[1],
					goodsList: list,
					validityTime: time,
					groupCount:this.ruleForm.maxGroupNumber,
					groupPeopleCount: this.ruleForm.groupNumber,
					publicityImg:this.ruleForm.publicityImg,
					rules:this.ruleForm.rules,
					shareImg:this.ruleForm.shareImgUrl,
					shareTitle:this.ruleForm.shareTitle,
					extraReward:this.ruleForm.extraReward,
					noPayType:this.ruleForm.noPayType,
				};
				let res = null;
				if (this.atyId!=0) {
					data.id = this.atyId;
					res = await editGroupActivity(data);
				} else {
					res = await addGroupActivity(data);
				}
				if (res.success) {
					this.$message({
						showClose: true,
						type: 'success',
						message: "保存成功"
					});
					this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
						(item) => {
							return item.path != this.$route.path;
						}
					);
					this.$router.push({
						path: '/market/spellGroup/index'
					});
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: res.alertMsg
					});
				}
				this.loading = false;
			},
			getRowKeys(row) {
				return row.skuId + '';
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				var isAll = val.length == this.skuList.length ? true : false;
				if (isAll) {
					this.checked = true;
				} else {
					this.checked = false;
				}
			},
			getRowKeys2(row) {
				return row.skuId + '';
			},
			handleSelectionChange2(val) {
				this.multipleSelection2 = val;
				var isAll = val.length == this.checkSkuList.length ? true : false;
				if (isAll) {
					this.checked2 = true;
				} else {
					this.checked2 = false;
				}
			},
			tabCheckedChange() {
				this.$refs['tab'].toggleAllSelection();
			},
			tabCheckedChange2() {
				this.$refs['tabSet'].toggleAllSelection();
			},
			//加入
			joinSkill() {
				this.skuList.map(item => {
					this.multipleSelection.map(items => {
						if (item.skuId == items.skuId) {
							item.hasSecKill = true;
						}
						return items;
					})
					return item;
				})
				//是否有库存不足规格
				var isStockOver= false;
				this.checkSkuList.map(item => {
					this.multipleSelection.map(items => {
						if (item.skuId == items.skuId) {
							items.secKillPrice = item.secKillPrice;
							items.secKillStock = item.secKillStock;
							items.directReward = item.directReward;
							items.indirectReward = item.indirectReward;
						}
						return items;
					})
					return item;
				})
				this.multipleSelection.map(item=>{
					if(item.stock==0){
						isStockOver = true;
					}
					return item;
				})
				if(isStockOver){
					this.$message({
						showClose: true,
						type: 'warning',
						message: '有规格库存不足，请重新选择'
					});
					return;
				}
				this.checkSkuList = this.multipleSelection;
				this.$refs['tab'].clearSelection();
				this.toNext();
			},

			//取消选择的规格
			cancelKillBefore(row, type) {
				this.clearKill(row, type);
			},
			//取消参加
			clearKill(row, type) {
				if (type == 1) {
					var list = [];
					this.checkSkuList.map(item => {
						if (item.skuId != row.skuId) {
							list.push(item);
						}
						return item;
					})
					this.checkSkuList = list;

					this.skuList.map(item => {
						if (item.skuId == row.skuId) {
							item.hasSecKill = false;
						}
						return item;
					})
				} else {
					var ids1 = [];
					var ids2 = [];
					this.multipleSelection2.map(item => {
						ids1.push(item.skuId);
						return item;
					})
					this.checkSkuList.map(item => {
						ids2.push(item.skuId);
						return item;
					})
					let arr = ids2.filter(function(val) {
						return ids1.indexOf(val) === -1;
					})

					var leaveSku = [];
					this.checkSkuList.map(item => {
						arr.map(items => {
							if (item.skuId == items) {
								leaveSku.push(item);
							}
							return items;
						})
						return item;
					})
					console.log("---leaveSku---", leaveSku);
					this.checkSkuList = leaveSku;

					this.skuList.map(item => {
						this.multipleSelection2.map(items => {
							if (item.skuId == items.skuId) {
								item.hasSecKill = false;
							}
							return items;
						})
						return item;
					})
				}
				this.checked2 = false;
				this.$refs['tabSet'].clearSelection();
			},
			toNext() {
				if (!this.checkSkuList.length) {
					this.$message({
						showClose: true,
						type: 'warning',
						message: '请选择要参加活动的规格'
					});
					return;
				}
				this.editableTabsValue = '2';
			},
			commitEdit() {
				if (this.checkSkuList.length ==0) {
					this.$message({
						showClose: true,
						type: 'warning',
						message: '至少选择一个规格'
					});
					return;
				}
				var isSetPrice = true;
				var isSetStock = true;
				var isSetDirect = true;
				var isSetIndirect = true;
				this.checkSkuList.map(item => {
					if (!item.secKillStock) {
						isSetStock = false;
					}
					if (!item.secKillPrice) {
						isSetPrice = false;
					}
					if (!item.directReward) {
						isSetDirect = false;
					}
					if(!item.indirectReward){
						isSetIndirect = false;
					}
					return item;
				})

				if (!isSetPrice || !isSetStock) {
					if (!isSetPrice) {
						this.$message({
							showClose: true,
							type: 'warning',
							message: '请填写拼团价'
						});
					} else if (!isSetStock) {
						this.$message({
							showClose: true,
							type: 'warning',
							message: '请填写拼团库存'
						});
					} 
					// else if (!isSetDirect){
					// 	this.$message({
					// 		showClose: true,
					// 		type: 'warning',
					// 		message: '请填写直接奖励'
					// 	});
					// }else if (!isSetIndirect){
					// 	this.$message({
					// 		showClose: true,
					// 		type: 'warning',
					// 		message: '请填写间接奖励'
					// 	});
					// }
					return;
				}

				var isOverStock = false;
				this.checkSkuList.map(item => {
					if (item.stock < item.secKillStock) {
						isOverStock = true;
					}
					return item;
				})

				if (isOverStock) {
					this.$message({
						showClose: true,
						type: 'warning',
						message: '有规格库存不足，请重新设置'
					});
					return;
				}
				var minPrice = 0;
				this.checkSkuList.map((item, index) => {
					item.secKillPrice = (+item.secKillPrice).toFixed(2);
					var price = +item.secKillPrice;
					if (index == 0) {
						minPrice = price;
					} else {
						if (price < minPrice) {
							minPrice = price;
						}
					}
					return item;
				})
				this.currentEditGoods.skuList = this.checkSkuList;
				this.currentEditGoods.secKillPrice = minPrice.toFixed(2);

				//判断是否存在
				let indexs = -1;
				this.ruleForm.activeGoodsList.map((item, index) => {
					if (item.id == this.currentEditGoods.id) {
						indexs = index;
					}
					return item;
				})
				if (indexs > -1) {
					this.ruleForm.activeGoodsList = this.ruleForm.activeGoodsList.map(item => {
						if (item.id == this.currentEditGoods.id) {
							item = this.currentEditGoods;
						}
						return item;
					})
				} else {
					this.ruleForm.activeGoodsList.push(this.currentEditGoods);
				}
				this.editProductDialogShow = false;
				this.editableTabsValue = '1';
				this.skuList = [];
				this.checkSkuList = [];
			},
			cancelEdit(){
				this.editProductDialogShow = false;
				this.editableTabsValue = '1';
				this.skuList = [];
				this.checkSkuList = [];
			},
			setPrice() {
				this.batchTitle = "批量设置拼团价";
				this.batchKillPrice = '';
				this.batchEditType = 1;
				this.batchEditDialog = true;
			},
			setStock() {
				this.batchTitle = "批量设置拼团库存";
				this.batchKillPrice = '';
				this.batchEditType = 2;
				this.batchEditDialog = true;
			},
			setPoint(type) {
				if(type==1){
					this.batchTitle = "批量设置直接奖励";
					this.batchEditType = 3;
				}else{
					this.batchTitle = "批量设置间接奖励";
					this.batchEditType = 4;
				}
				this.batchKillPrice = '';
				this.batchEditDialog = true;
			},
			inputChange(val) {
				if (this.batchEditType == 1 || this.batchEditType == 3) {
					if (val < 0) {
						this.batchKillPrice = (Math.abs(val)).toFixed(2);
					}
				} else if (this.batchEditType == 2) {
					var count = val;
					this.batchKillPrice = parseInt(count);
				}
				this.$forceUpdate()
			},
			//确定批量设置
			batchEditCommit() {
				var str = '';
				if (this.batchEditType == 1 && !this.batchKillPrice) {
					str = '请填写拼团价';
				} else if (this.batchEditType == 2 && !this.batchKillPrice) {
					str = '请填写拼团库存';
				} else if (this.batchEditType == 3 && !this.batchKillPrice) {
					str = '请填写直接奖励';
				} else if (this.batchEditType == 4 && !this.batchKillPrice) {
					str = '请填写间接奖励';
				}else if (isNaN(this.batchKillPrice)) {
					str = '请填写正确的数值';
				}
				if (str) {
					this.$message({
						showClose: true,
						type: 'warning',
						message: str
					});
					return;
				}
				var tempNum = this.batchKillPrice;
				let temp = JSON.parse(JSON.stringify(this.checkSkuList));
				if (this.batchEditType == 1) {
					var money =  parseFloat(tempNum);
					var arr = tempNum.split('.');
					var num = '';
					var price = '';
					if(arr.length>1){
						if(arr[1].length>2){
							num = arr[1].substr(0,2);
						}else{
							num = arr[1];
						}
						price = arr[0]+'.'+num;
					}else{
						price = money;
					}
					temp.map(item => {
						item.secKillPrice = price+'';
						return item;
					})
					
				} else if (this.batchEditType == 2) {
					var isOverStock = false;
					temp.map(item => {
						if (item.stock < tempNum) {
							isOverStock = true;
						}
						return item;
					})
					if (isOverStock) {
						this.$message({
							showClose: true,
							type: 'warning',
							message: '有规格库存不足，请重新设置'
						});
					} else {
						temp.map(item => {
							item.secKillStock = tempNum+'';
							return item;
						})
					}
				} else {
					var money1 =  parseFloat(tempNum);
					var arr1 = tempNum.split('.');
					var num1 = '';
					var price1 = '';
					if(arr1.length>1){
						if(arr1[1].length>2){
							num1 = arr1[1].substr(0,2);
						}else{
							num1 = arr1[1];
						}
						price1 = arr1[0]+'.'+num1;
					}else{
						price1 = money1;
					}
					temp.map(item => {
						if(this.batchEditType==3){
							item.directReward = price1+'';
						}else{
							item.indirectReward = price1+'';
						}
						return item;
					})
				}
				this.checkSkuList = temp; 
				this.batchEditDialog = false;
				this.$forceUpdate();
				console.log("---tempNum---", this.checkSkuList);
			},
			//编辑所选商品
			goodsEdit(row) {
				this.currentEditGoods = row;
				this.checkSkuList = row.skuList;
				this.seckillSkuListNew();
			},
			//删除所选商品
			async deleteChose(row) {
				let _this = this;
				this.$confirm('是否确认删除当前商品', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					var baselist = [];
					_this.ruleForm.activeGoodsList.map(item => {
						if (row.id != item.id) {
							baselist.push(item);
						}
						return item;
					})
					_this.ruleForm.activeGoodsList = baselist;
				})
			},
			//回显选中的规格
			handleClick(tab) {
				if(tab.index==0){
					var that = this;
					this.checked2 = false;
					this.$nextTick(()=>{
						this.$refs['tab'].clearSelection();
						var list = [];
						this.checkSkuList.map(item=>{
							this.skuList.map(sItem=>{
								if(item.skuId == sItem.skuId){
									list.push(sItem);
								}
								return sItem;
							})
							return item;
						})
						setTimeout(function(){
							list.map(item => {
								that.$refs['tab'].toggleRowSelection(item,true);
							})
						},100)
					})
					
				}
			},
			// 上传奖品图成功钩子
			handleAvatarSuccess1(res) {
				console.log("---11----",res);
				this.ruleForm.publicityImg = res.data[0];
				return true;
			},
			// 上传奖品图成功钩子
			handleAvatarSuccess(res) {
				console.log("---22----",res);
				this.ruleForm.shareImgUrl = res.data[0];
				return true;
			},
			// 上传图前钩子
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
				// const isLt3M = file.size / 1024 / 1024 < 3;
				if (!isJPG) {
					this.$message.error('上传图片必须是 JPG 或者PNG格式!');
				}
				// if (!isLt3M) {
				// 	this.$message.error('图片大小请控制在3M以内!');
				// }
				return isJPG;
			},
			// 取消
			suppilerCansel() {
				this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
					(item) => {
						return item.path != this.$route.path;
					}
				);
				this.$router.push({
					path: '/market/promotionActivities/index'
				});
			},
		}
	}
</script>

<style lang="less" scoped>
	.bottom-save-btn {
		position: fixed;
		width: 100%;
		background: #fff;
		bottom: 0;
		text-align: center;
		padding: 20px 0;
		z-index: 100;
		margin-left: -20px;
		box-shadow: 13px 1px 6px #999;
	}
	/deep/.el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		height: 146px;
	}
	
	/deep/.el-upload:hover {
		border-color: #409eff;
	}
	.baseInformtion {
		width: 100%;
		background: #fff;
		padding: 10px;
	
		.infortion {
			margin-bottom: 20px;
			padding: 10px 0;
			border-bottom: 1px solid #ccc;
		}
	}
</style>
