<template>
	<div class="contanier">
		<div class="filter-container title-border">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">商品名称:</label>
				<el-input v-model="searchKey" style="width: 200px;" placeholder="商品名称"></el-input>
			</div>
			<div class="filter-item">
				<label class="label">商品编码:</label>
				<el-input v-model="searchCode" style="width: 200px;" placeholder="商品编码"></el-input>
			</div>
			<!-- 商品分组 -->
			<!-- <div class="filter-item">
				<label class="label">商品分组:</label>
				<el-select v-model="group" style="width: 200px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option
						v-for="(item,index) in groupOptions"
						:key="index"
						:label="item.groupName"
						:value="item.groupId"
					></el-option>
				</el-select>
			</div> -->
			<div class="filter-item">
				<label class="label">商品分类:</label>
				<el-cascader
					v-model="categoryId"
					:options="CategorySelectItemList"
					:props="{ value: 'id', label: 'categoryName' ,children:'childCategoryList'}"
					clearable
					style="width:300px;"
				></el-cascader>
			</div>
			<div class="filter-item">
				<label class="label">商品标签:</label>
				<el-select v-model="tagId" filterable clearable style="width: 200px;margin-right: 10px;">
					<el-option 
						v-for="item in tagsList" 
						:key="item.id" 
						:label="item.tagsName" 
						:value="item.id">
					</el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin:0 10px;width:90px;" @click="handleFilter">查询</el-button>
			</div>
		</div>
		<!-- table -->
		<div class="table-container content">
			<!-- :row-key="getRowKeys" -->
			<el-table
				height="450"
				:data="tableDataList"
				v-loading="loading"
				style="width: 100%"
				ref="compSelectProTable"
				@selection-change="handleSelectionChange"
			>
				<!-- <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column> -->
				<el-table-column prop="goodsName" label="商品" width="400">
					<template slot-scope="scope">
						<div class="dialog-name-content">
							<img v-if="scope.row.thumbImgUrl" :src="imgUrl+scope.row.thumbImgUrl" />
							<div class="right">
								<div class="name" style="width: 500px;color: #007AFF;">
									<pre
										style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;"
									>{{scope.row.goodsName}}</pre>
								</div>
								<div style="margin-top: 10px;color: #f00;">￥{{scope.row.salePrice}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="salePrice" label="销售价格"></el-table-column>
				<el-table-column prop="growRate" label="活跃值"></el-table-column>
				<el-table-column prop="totalStock" label="商品库存"></el-table-column>
				<el-table-column prop="noChoiceReason" label="不可选原因" width="200px"></el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<div class="table-button" style="background-color: #409EFF;border-radius: 5px;width: 90px;color: #fff;text-align: center;height: 35px;line-height: 35px;" v-if="scope.row.choiceSign" @click="saveSelectProList(scope.row)">选择商品</div>
						<div class="table-button" style="border-radius: 5px;width: 90px;color: #999;text-align: center;height: 35px;line-height: 35px;" v-else>不可选</div>
					</template>
				</el-table-column>
			</el-table>

			<!-- <el-checkbox
				style="margin-top:25px;margin-left:13px;float:left;"
				:value="checkedAll"
				@change="selectCurrentAll"
			>当前页全选</el-checkbox> -->
			<el-pagination
				v-if="page.total"
				style="margin-top:20px;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="page.current"
				:page-sizes="[5,10, 15, 20, 25, 30]"
				:page-size="page.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="page.total"
			></el-pagination>
		</div>
		<!-- <div slot="footer" class="dialog-footer">
			已选中
			<span style="color:#F56C6C">{{multipleSelection.length}}</span>
			件
			<el-button class="button" @click="saveSelectProList" style="margin-left:10px;" type="primary">确定</el-button>
		</div> -->
	</div>
</template>
<script>
import {
	productGroupListData,
	groupGoodsList,
	listTags,
	categoryList,
} from "@/api/goods.js";
import config from '@/config/index'

export default {
	name: 'selectProduce',
	props: {
		api: String, //弹框商品列表请求接口名称, 统一加在 api/popGoods.js里
		selectedData: { //需要选中的商品列表
			type: Array,
			default: () => {
				return []
			}
		},
		couponData: {
			type: Object,
			default: () => {
				return {}
			}
		},
		params: { //自定义请求参数
			type: Array,
			default: () => {
				return []
			}
		},
		isShowCheck: {
			type: Boolean,
			default: () => {
				return true
			}
		},
		isShowSpecValue: {
			type: Boolean,
			default: () => {
				return false
			}
		}
	},
	data () {
		return {
			baseAPI: config.BASE_URL,
			imgUrl: config.IMG_BASE,
			searchKey: '',
			searchCode: '',
			type: null,
			typeOptions: [{
				value: 0,
				label: '单品销售'
			},
			{
				value: 1,
				label: '组合销售'
			}
			],
			group: null,
			groupOptions: [],
			goodsStatus: null,
			goodsStatusOptions: [
				{
					value: 0,
					label: '已下架'
				},
				{
					value: 1,
					label: '上架中'
				},
				{
					value: 2,
					label: '售罄'
				}
			],
			page: {
				total: 0,
				current: 1,
				size: 10
			},
			tableDataList: [],
			selectCount: 0,
			multipleSelection: [],
			checkedShopPro: true,
			loading: false,
			tagsList:[],//商品标签数据
			tagId:'',//商品标签id
			CategorySelectItemList: [],  //分类
			categoryId:[],
		}
	},
	created () {
		this.getTagList();
		this.getInfor();
		// this.getGroupList();
		this.getSingleList();

	},
	watch: {},
	computed: {
		checkedAll () {
			var val = this.multipleSelection;
			let ableSelectLength = 0 //获取当前页可选的全部页数
			var ids = val.map(item => {
				return item.id
			})
			this.tableDataList.forEach(item => {
				if (ids.indexOf(item.id) > -1) {
					ableSelectLength++;
				}
			});
			return (ableSelectLength > 0 && this.tableDataList.length == ableSelectLength) ? true : false;
		}
	},
	mounted () {


	},
	methods: {
		//获取商品标签数据
		async getTagList () {
			try {
				let data = {
					pageNo: 0,
					pageSize: 1000,
				};
				let res = await listTags(data);
				if (res.success) {
					this.tagsList = res.data.records;
				}
			} catch (e) {
				//TODO handle the exception
				console.log(e);
			}
		},
		//初始化加载数据
		async getInfor () {
			try {
				let result = await categoryList()
				this.formatCategoryList(result.data);
				this.CategorySelectItemList = result.data;
				let data = {
					pageNo: 0,
					pageSize: 10000,
				};
				//获取标签数据
				let tags = await listTags(data);
				this.tagList = tags.data.records;
			} catch (e) {
				console.log(e)
			}
		},
		//格式化类目数据
		formatCategoryList (array) {
			for (let i = 0; i < array.length; i++) {
				if (array[i].categoryLevel == 3) {
					delete array[i].childCategoryList;
				} else {
					this.formatCategoryList(array[i].childCategoryList);
				}
			}
		},
		// 获取分组列表数据
		async getGroupList () {
			try {
				// this.loading = true
				let data = {
					pageNo: 0, // 略过数据
					pageSize: 10000, // 取的数据
				}
				let result = await productGroupListData(data);
				this.groupOptions = result.data.records || [];
		
			} catch (e) {
				console.log(e)
			} 
		
		},
		async getSingleList () {
			this.loading = true;
			try {
				
				let data = {
					pageNo: this.page.current, // 略过数据
					pageSize: this.page.size, // 取的数据
					// categoryId:this.categoryId,
					goodsName:this.searchKey,
					tagId:this.tagId,
					goodsCode:this.searchCode,
				};
				//类目
				console.log("--111---",this.categoryId)
				if (this.categoryId.length) {
					data.categoryId = this.categoryId[2]
				}
				let result = await groupGoodsList(data);
				this.page.total = result.data.total;
				result.data.records.map(items=>{
					items.hasSecKill = false;
					items.quotaNum = '0';
					items.groupWard='';
					return items;
				})
				this.selectedData.map(item=>{
					result.data.records.map(items=>{
						if(item.goodsId==items.goodsId){
							items.hasSecKill = true;
						}
						return items;
					})
					return item;
				})
				this.tableDataList = result.data.records;

			} catch (error) {
				console.log(error);
			} finally {
				if (!this.isReady) {
					console.log("------", this.selectedData)
					this.selectedData.map(item => {
						this.$refs['compSelectProTable'].toggleRowSelection(item, true);
					})
					this.isReady = true;
				}
				this.loading = false;
			}
		},
		//外部全选当前页
		selectCurrentAll (e) {
			this.$refs['compSelectProTable'].toggleAllSelection();
			console.log(e);
		},
		//当前页全选
		handleSelectionChange (val) {
			this.multipleSelection = val;
		},
		// 切换显示条数
		handleSizeChange (val) {
			this.page.size = val;
			this.getSingleList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.page.current = val;
			this.getSingleList()
		},
		// selectInit(row, id) {
		// 	return (row.Remark && row.Remark != '') ? false:true
		// },
		clearSelection () {
			this.$nextTick(() => {
				this.$refs.compSelectProTable.clearSelection();
			});
		},
		getRowKeys (row) {
			return row.id;
		},
		handleFilter () {
			console.log(this.group);
			this.page.current = 1;
			this.getSingleList();
		},
		//关闭弹框，以及曝光选择的列表
		saveSelectProList (row) {
			this.multipleSelection = [];
			this.multipleSelection.push(row);
			this.$emit('getSelectList', this.multipleSelection)
		}

	}
}
</script>
<style lang="less" scoped>
.contanier {
	position: relative;
	overflow: hidden;
}

.content {
	overflow: hidden;
}

.dialog-footer {
	margin-top: 20px;
	text-align: center;

	.button {
		width: 150px;
	}
}

.dialog-name-content {
	margin: 10px 0;
	display: flex;
	flex-direction: row;

	img {
		width: 60px;
		height: 60px;
		object-fit: cover;
		margin-right: 10px;
	}
}
.noImgIcon {
	width: 60px;
	height: 60px;
}
</style>
